<template>
  <div class="news">
    <!-- header -->
    <div class="head">
      <Navigator />
      <h3>资讯动态</h3>
      <p>Information trends</p>
    </div>
    <!-- header -->
    <!-- content -->
    <Content :data="content" :dataTotal="dataTotal" />
    <!-- content -->
    <!-- 分页 -->
    <el-pagination background layout="prev, pager, next" :total="dataTotal" :page-size="5" @current-change="handleCurrentChange"></el-pagination>
    <!-- 分页 -->
    <Footer />
  </div>
</template>

<script>
import { getNewLableApi } from "@/api/new";
export default {
  components: {
    Content: () => import("./component/Content"),
  },
  data() {
    return {
      content: [],
      dataTotal: 0,
      page: 0,
    };
  },
  async created() {
    await this.initDateFn();
  },
  methods: {
    async initDateFn() {
      let res = await getNewLableApi(this.page);
      this.content = res.data.content;
      this.dataTotal = parseInt(res.data.totalElements);
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.initDateFn();
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 750px) {
  .news {
    width: 100%;
    height: 100%;
    position: relative;
    .head {
      height: 8rem;
      background: url(../../assets/img/news-bj.png) no-repeat;
      background-size: 100% 100%;
      overflow: hidden;
      // text-align: center;
      h3 {
        margin-top: 4.35rem;
        font-size: 1.02rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.18rem;
        text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
        text-align: center;
      }
      p {
        font-size: 0.41rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #ffffff;
        line-height: 0.82rem;
        text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
        text-align: center;
      }
    }
    .el-pagination {
      background-color: #f4f4f4;
      text-align: center;
      padding: 10px 0;
      padding-right: 10px;
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .news {
    width: 100%;
    height: 100%;
    position: relative;
    .head {
      height: 5.48rem;
      width: 19.2rem;
      background: url(../../assets/img/news-bj.png) no-repeat;
      background-size: 100% 100%;
      overflow: hidden;
      text-align: center;
      h3 {
        width: 4rem;
        height: 1.4rem;
        font-size: 1rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #ffffff;
        line-height: 1.17rem;
        text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
        text-align: center;
        margin-top: 2.71rem;
        margin-left: 7.59rem;
      }
      p {
        // width: 3.86rem;
        height: 0.98rem;
        font-size: 0.4rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #ffffff;
        line-height: 0.47rem;
        text-shadow: 0rem 4rem 4rem rgba(108, 108, 108, 0.25);
        // margin-left: 7.65rem;
      }
    }
    .el-pagination {
      background-color: #f4f4f4;
      text-align: center;
      padding: 10px 0;
      padding-right: 10px;
    }
  }
}
</style>
